import styled from '@emotion/styled'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { CompanyData } from 'app/containers/Footer/CompanyData'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Facebook, Instagram } from 'react-feather'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  companyName?: string
  companyAddress?: string
  cin?: string
  cir?: string
  email?: string
  facebookURL?: string
  fax?: string
  frontOfficePhone?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  mobilePhone?: string
  pec?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  sdi?: string
  siteName?: string
  vat?: string
  whatsapp?: string
  whatsappURL?: string
}

export const Footer = memo(function Footer({
  address,
  cin,
  cir,
  companyAddress,
  companyName,
  email,
  facebookURL,
  fax,
  frontOfficePhone,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  logo,
  mobilePhone,
  pec,
  phone,
  policyMenu,
  sdi,
  siteName,
  vat,
  whatsapp,
  whatsappURL,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container>
      {logo ? (
        <Logo src={logo} alt={siteName} width="172" height="120" />
      ) : null}
      {address ? <Address>{address}</Address> : null}
      <Contacts dial={2} row wrap>
        {phone ? (
          <Phone
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'Website',
                    event_label: 'Phone Number',
                  })

                typeof window._paq !== 'undefined' &&
                  window._paq.push([
                    'trackEvent',
                    'Website',
                    'Click',
                    'Phone Number',
                  ])
              }
            }}
          >
            {`${useVocabularyData('ph', languageCode)} `}
            {phone}
          </Phone>
        ) : null}
        {whatsappURL ? (
          <MobilePhone
            aria-label="Whatsapp"
            href={whatsappURL}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'Website',
                    event_label: 'Whatsapp',
                  })

                typeof window._paq !== 'undefined' &&
                  window._paq.push([
                    'trackEvent',
                    'Website',
                    'Click',
                    'Whatsapp',
                  ])
              }
            }}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.998"
              height="24"
              viewBox="0 0 23.998 24"
            >
              <path
                d="M20333-1737a11.906,11.906,0,0,1-6.6-1.983l-4.617,1.47,1.5-4.455A11.869,11.869,0,0,1,20321-1749a12.009,12.009,0,0,1,12-12,12.013,12.013,0,0,1,12,12A12.013,12.013,0,0,1,20333-1737Zm-4.523-18.511a1.7,1.7,0,0,0-1.225.429l-.035.035a3.828,3.828,0,0,0-1.189,2.882,6.757,6.757,0,0,0,1.416,3.611l.008.01c.012.016.033.047.063.092a14.536,14.536,0,0,0,5.908,5.183,10.229,10.229,0,0,0,3.514.988,2.871,2.871,0,0,0,.689-.084,3.359,3.359,0,0,0,2.357-1.692,2.972,2.972,0,0,0,.207-1.661c-.072-.124-.234-.2-.479-.317-.025-.013-.057-.027-.084-.041l-.113-.055c-.285-.144-2.051-1.014-2.387-1.133a.873.873,0,0,0-.3-.062.668.668,0,0,0-.555.322l-.148.208a12.716,12.716,0,0,1-.791,1.032.673.673,0,0,1-.5.205.917.917,0,0,1-.34-.067c-.029-.013-.061-.027-.1-.042l-.037-.016a8.788,8.788,0,0,1-2.678-1.68,10.511,10.511,0,0,1-1.945-2.419.557.557,0,0,1,.129-.739l.008-.012c.107-.131.209-.239.318-.354l.01-.011c.061-.061.127-.133.193-.209l.045-.05.018-.021a2.033,2.033,0,0,0,.328-.481.722.722,0,0,0-.053-.651c-.064-.135-.486-1.156-.729-1.752-.1-.229-.187-.448-.266-.63l-.086-.214c-.227-.552-.4-.581-.76-.6-.057,0-.115,0-.178,0h-.01C20328.627-1755.508,20328.557-1755.51,20328.479-1755.51Z"
                transform="translate(-20321 1760.999)"
              />
            </svg>
            {whatsapp}
          </MobilePhone>
        ) : null}
        {frontOfficePhone ? (
          <OfficePhone href={`tel:${frontOfficePhone}`}>
            {`${useVocabularyData('front-office', languageCode)} `}
            {frontOfficePhone}
          </OfficePhone>
        ) : null}
        {fax ? (
          <Fax>
            {`${useVocabularyData('fax', languageCode)} `}
            {fax}
          </Fax>
        ) : null}
      </Contacts>
      {email ? (
        <Email
          href={`mailto:${email}`}
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'click', {
                event_category: 'Website',
                event_label: 'Email Address',
              })

            typeof window._paq !== 'undefined' &&
              window._paq.push([
                'trackEvent',
                'Website',
                'Click',
                'Email Address',
              ])
          }}
        >
          {email}
        </Email>
      ) : null}
      <Social dial={5} row>
        {facebookURL ? (
          <SocialItem
            aria-label="Facebook"
            href={facebookURL}
            rel="noreferrer"
            target="_blank"
          >
            <Facebook />
          </SocialItem>
        ) : null}
        {instagramURL ? (
          <SocialItem
            aria-label="Instagram"
            href={instagramURL}
            rel="noreferrer"
            target="_blank"
          >
            <Instagram />
          </SocialItem>
        ) : null}
        {whatsappURL ? (
          <SocialItem
            aria-label="Whatsapp"
            href={whatsappURL}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.998"
              height="24"
              viewBox="0 0 23.998 24"
            >
              <path
                d="M20333-1737a11.906,11.906,0,0,1-6.6-1.983l-4.617,1.47,1.5-4.455A11.869,11.869,0,0,1,20321-1749a12.009,12.009,0,0,1,12-12,12.013,12.013,0,0,1,12,12A12.013,12.013,0,0,1,20333-1737Zm-4.523-18.511a1.7,1.7,0,0,0-1.225.429l-.035.035a3.828,3.828,0,0,0-1.189,2.882,6.757,6.757,0,0,0,1.416,3.611l.008.01c.012.016.033.047.063.092a14.536,14.536,0,0,0,5.908,5.183,10.229,10.229,0,0,0,3.514.988,2.871,2.871,0,0,0,.689-.084,3.359,3.359,0,0,0,2.357-1.692,2.972,2.972,0,0,0,.207-1.661c-.072-.124-.234-.2-.479-.317-.025-.013-.057-.027-.084-.041l-.113-.055c-.285-.144-2.051-1.014-2.387-1.133a.873.873,0,0,0-.3-.062.668.668,0,0,0-.555.322l-.148.208a12.716,12.716,0,0,1-.791,1.032.673.673,0,0,1-.5.205.917.917,0,0,1-.34-.067c-.029-.013-.061-.027-.1-.042l-.037-.016a8.788,8.788,0,0,1-2.678-1.68,10.511,10.511,0,0,1-1.945-2.419.557.557,0,0,1,.129-.739l.008-.012c.107-.131.209-.239.318-.354l.01-.011c.061-.061.127-.133.193-.209l.045-.05.018-.021a2.033,2.033,0,0,0,.328-.481.722.722,0,0,0-.053-.651c-.064-.135-.486-1.156-.729-1.752-.1-.229-.187-.448-.266-.63l-.086-.214c-.227-.552-.4-.581-.76-.6-.057,0-.115,0-.178,0h-.01C20328.627-1755.508,20328.557-1755.51,20328.479-1755.51Z"
                transform="translate(-20321 1760.999)"
              />
            </svg>
          </SocialItem>
        ) : null}
      </Social>
      {policyMenu ? (
        <Policy dial={5} row wrap>
          {uniqBy(policyNav, 'title').map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </Policy>
      ) : null}
      <GDS>
        Amadeus FG FLR421 • Galileo/Apollo FG A7239 • Sabre FG 057182 •
        WorldSpan FG 96421
      </GDS>
      <Bottom dial={7} row space="between" wrap>
        {vat ? (
          <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
        ) : null}
        {siteName ? (
          <SiteName>
            © {new Date().getFullYear()} {siteName}
          </SiteName>
        ) : null}
        <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
          {`${useVocabularyData('credits', languageCode)} QNT SRL`}
        </Credits>
        <CompanyData
          cin={cin}
          cir={cir}
          pec={pec}
          phone={phone}
          vat={vat}
          sdi={sdi}
          companyAddress={companyAddress}
          companyName={companyName}
          email={email}
          languageCode={languageCode}
        />
      </Bottom>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="desktopSmall">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            IBEID={IBEID}
            whatsappURL={whatsappURL}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding: 3.125rem 0 2.25rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 3.75rem 1.9375rem 2.25rem;
  }
`

const Logo = styled.img`
  margin-bottom: 2.125rem;

  @media (max-width: 1023px) {
    width: auto;
    height: 80px;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 2.125rem;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.875rem;
  }
`

const Contacts = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 2.125rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    height: 2px;
    opacity: 0.1;
  }
  &:before {
    margin-right: 7.778vw;
  }
  &:after {
    margin-left: 7.778vw;
  }

  a {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: '';
      width: 0.3125rem;
      height: 0.3125rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight1};
      margin: 0 0.75rem;
      transform: rotate(45deg);
    }
  }

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.875rem;
    &:before,
    &:after {
      display: none;
    }

    a {
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
`

const Phone = styled.a``

const MobilePhone = styled.a`
  svg {
    height: 1rem;
    margin-right: 0.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const OfficePhone = styled.a``

const Fax = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Email = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 2.125rem;
  &:hover {
    text-decoration: underline;
  }
`

const Social = styled(FlexBox)`
  margin: 40px auto 0;
`

const SocialItem = styled.a`
  margin: 0 0.75rem;
  opacity: 0.4;
  transition: 0.1s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &[aria-label='Facebook'],
  &[aria-label='Whatsapp'] {
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 0;
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Policy = styled(FlexBox)`
  margin-top: 4.5rem;
`

const GDS = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 0.75rem;
  opacity: 0.4;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 1.5rem;
  }
`

const Bottom = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 2.625rem;
  padding: 0 10.972vw;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    padding: 0;
  }
`

const Vat = styled.div`
  opacity: 0.4;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const SiteName = styled.div`
  opacity: 0.4;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Credits = styled.a`
  opacity: 0.4;
  transition: 0.1s ease-in-out;
  &:hover {
    opacity: 1;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 0.75rem;
  }
`
